import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import lerp from "lerp";
import { cloneDeep } from "lodash";
import pick from "lodash/pick";

import Avatar from "@/components/Avatar";
import Header from "@/components/Header";
import { HEIGHT as SCALE_HEIGHT, WIDTH as SCALE_WIDTH } from "@/components/pages/play/ScaleContainer";

import { countries } from "@/data/flags";

import { sfx } from "@/helpers/audio";
import gsap from "@/helpers/gsap";
import { getConnectedPlayers } from "@/helpers/player";
import { formatPoints } from "@/helpers/stringHelper";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import useElementSize from "@/hooks/useElementSize";
import useForwardRef from "@/hooks/useForwardRef";
import useRefMap from "@/hooks/useRefMap";
import useViewportSize from "@/hooks/useViewportSize";

import HostIcon from "@/images/icons/icon-label-host.svg";

import { LEADERBOARD_IDLE, LEADERBOARD_RIGHT, LEADERBOARD_WRONG, YELLOW_BROWN } from "@/colors";
import { PLAYING_HOST_CONNECTION_ID } from "@/constants";

const LEADERBOARD_NUM_VISIBLE = 10;

const LEADERBOARD_ITEM_SCORE_STYLE = {
	top: "-0.2rem",
};

const LEADERBOARD_AVATAR_SIZE = 80 * 2;

const DESKTOP_SCALE_HEIGHT = 1080;

export default function Leaderboard({
	className,
	onLoad,
	onComplete,
	onSendScore,
	leaderboard,
	hidePlayerCountry = false,
}) {
	const [completed, setCompleted] = useState(false);

	const onLoadRef = useRef(onLoad);
	useEffect(() => void (onLoadRef.current = onLoad), [onLoad]);

	const onCompleteRef = useRef(onComplete);
	useEffect(() => void (onCompleteRef.current = onComplete), [onComplete]);

	useEffect(() => {
		setCompleted(false);
		if (onLoadRef.current) {
			onLoadRef.current();
		}
	}, []);

	useEffect(() => {
		if (completed && onCompleteRef.current) {
			onCompleteRef.current();
		}
	}, [completed]);

	return (
		<LeaderboardView
			className={className}
			onSendScore={onSendScore}
			entities={leaderboard || []}
			setCompleted={setCompleted}
			hidePlayerCountry={hidePlayerCountry}
		/>
	);
}

export function LeaderboardView({
	className,
	entities, // an entity is a player or a team (of type PlayerOrTeam)
	setCompleted,
	onSendScore,
	hidePlayerCountry = false,
	...props
}) {
	const ref = useRef();
	const [getLeaderboardItemsRef, setLeaderboardItemRef] = useRefMap();

	const [scrollbarEnabled, setScrollbarEnabled] = useState(false);
	const [leftAlign, setLeftAlign] = useState(false);

	const { isDesktop } = useViewportSize();

	const heightMeasureRef = useRef(null);
	const { domRect: boundingClientRect } = useElementSize(heightMeasureRef.current);

	const height = useMemo(() => {
		if (isDesktop) {
			return DESKTOP_SCALE_HEIGHT;
		} else if (boundingClientRect) {
			return boundingClientRect.height - boundingClientRect.top;
		} else {
			return 0;
		}
	}, [boundingClientRect, isDesktop]);

	const heightRef = useRef(height);
	useEffect(() => void (heightRef.current = height), [height]);

	const allPlayersHaveZeroPoints = useMemo(() => entities.every((player) => player.previousPoints === 0), [entities]);
	const allLockedPlayersHaveZeroPointsRef = useRef(allPlayersHaveZeroPoints);
	useEffect(
		() => void (allLockedPlayersHaveZeroPointsRef.current = allPlayersHaveZeroPoints),
		[allPlayersHaveZeroPoints]
	);

	const setCompletedRef = useRef(setCompleted);
	useEffect(() => {
		setCompletedRef.current = setCompleted;
	}, [setCompleted]);

	useEffect(() => {
		let timeline = null;
		timeline = gsap.timeline({
			onComplete: () => {
				if (setCompletedRef.current) {
					setCompletedRef.current(true);
				}
			},
		});

		timeline.add(() => void setScrollbarEnabled(false), ">");
		timeline.add(() => void setLeftAlign(false), ">");

		// Slide players into position in sequence
		timeline.add("animateIn", 1);

		for (const player of entities.filter((a) => a.isVisibleBeforeReorder || a.isVisibleAfterReorder)) {
			const leaderboardItemRef = getLeaderboardItemsRef(player.connectionId);
			if (leaderboardItemRef && leaderboardItemRef.current) {
				leaderboardItemRef.current.setRank(player.rankBeforeReorder + 1);
			}
		}

		const leaderboardPlayers = allLockedPlayersHaveZeroPointsRef.current
			? entities.filter((a) => a.isVisibleAfterReorder).sort((a, b) => a.rankAfterReorder - b.rankAfterReorder)
			: entities
					.filter((a) => a.isVisibleBeforeReorder)
					.sort((a, b) => a.rankBeforeReorder - b.rankBeforeReorder);

		for (let i = 0; i < leaderboardPlayers.length; i++) {
			const player = leaderboardPlayers[i];
			const leaderboardItemRef = getLeaderboardItemsRef(player.connectionId);
			if (leaderboardItemRef && leaderboardItemRef.current) {
				const leaderboardItem = leaderboardItemRef.current;
				leaderboardItem.setRank(i + 1);
				leaderboardItem.setPoints(
					allLockedPlayersHaveZeroPointsRef.current ? player.points : player.previousPoints
				);
				leaderboardItem.setScore(player.points - player.previousPoints);

				if (allLockedPlayersHaveZeroPointsRef.current && player.points) {
					leaderboardItem.backgroundElement.style.backgroundColor = LEADERBOARD_RIGHT;
				}

				if (leaderboardPlayers.length === 1) {
					const target = { progress: 0 };
					timeline.to(
						target,
						{
							duration: 0.5,
							progress: 1,
							ease: "linear",
							delay: 0.25,
							onStart: () => {
								if (leaderboardItem.backgroundElement) {
									gsap.set(leaderboardItem.backgroundElement, { display: "flex" });
								}
								if (i === 0) {
									// void sfx.play("lbAppears");
								}
							},
							onUpdate: () => {
								if (leaderboardItemRef.current) {
									const leaderboardItem = leaderboardItemRef.current;
									if (leaderboardItem.boxElement) {
										gsap.set(leaderboardItem.boxElement, {
											opacity: target.progress,
											y: getItemYPos(
												heightRef.current,
												leaderboardItem.boxElement,
												i + LEADERBOARD_NUM_VISIBLE / 2 - 1,
												Math.min(leaderboardPlayers.length, LEADERBOARD_NUM_VISIBLE)
											),
										});
									}
								}
							},
						},
						"animateIn"
					);
				} else {
					const target = { progress: 0 };
					timeline.to(
						target,
						{
							duration: 0.75,
							progress: 1,
							ease: "none",
							onStart: () => {
								if (leaderboardItemRef.current) {
									const leaderboardItem = leaderboardItemRef.current;
									if (leaderboardItem.backgroundElement) {
										gsap.set(leaderboardItem.backgroundElement, { display: "flex" });
									}
									if (leaderboardItem.boxElement) {
										gsap.set(leaderboardItem.boxElement, {
											opacity: 0,
											y: getItemYPos(
												heightRef.current,
												leaderboardItem.boxElement,
												i,
												Math.min(leaderboardPlayers.length, LEADERBOARD_NUM_VISIBLE)
											),
										});
									}
								}
								if (i === 0) {
									// void sfx.play("lbAppears");
								}
							},
							onUpdate: () => {
								if (leaderboardItemRef.current) {
									const leaderboardItem = leaderboardItemRef.current;
									if (leaderboardItem.boxElement) {
										gsap.set(leaderboardItem.boxElement, {
											opacity: target.progress,
										});
									}
								}
							},
						},
						"animateIn"
					);

					/*
					timeline.to(
						target,
						{
							duration: 1.2,
							progress: 1,
							ease: "power2.out",
							delay: i * 0.25,
							onStart: () => {
								if (leaderboardItemRef.current) {
									const leaderboardItem = leaderboardItemRef.current;
									if (leaderboardItem.backgroundElement) {
										gsap.set(leaderboardItem.backgroundElement, { display: "flex" });
									}
								}
								if (i === 0) {
									void sfx.play("lbAppears");
								}
							},
							onUpdate: () => {
								if (leaderboardItemRef.current) {
									const leaderboardItem = leaderboardItemRef.current;
									if (leaderboardItem.boxElement) {
										gsap.set(leaderboardItem.boxElement, {
											y:
												heightRef.current * (1 - target.progress) +
												getItemYPos(
													heightRef.current,
													leaderboardItem.boxElement,
													i,
													Math.min(leaderboardPlayers.length, LEADERBOARD_NUM_VISIBLE)
												),
										});
									}
								}
							},
						},
						"animateIn"
					);
					*/
				}
			}
		}

		if (allLockedPlayersHaveZeroPointsRef.current) {
			timeline.add(() => {
				if (onSendScore) {
					onSendScore();
				}
			}, ">");
		}

		timeline.add(() => void setScrollbarEnabled(true), ">");

		if (!allLockedPlayersHaveZeroPointsRef.current) {
			const sortedPlayersByScore = entities
				.filter((a) => a.isVisibleBeforeReorder && a.points > a.previousPoints)
				.sort((a, b) => b.points - b.previousPoints - (a.points - a.previousPoints));

			timeline.add(() => void setLeftAlign(true), ">");

			timeline.add("showScores");
			let t = 0;

			// Reveal awarded points for each player in sequence
			for (const [i, player] of sortedPlayersByScore
				.filter((player) => player.points > player.previousPoints)
				.entries()) {
				const leaderboardItemRef = getLeaderboardItemsRef(player.connectionId);
				if (leaderboardItemRef && leaderboardItemRef.current) {
					const leaderboardItem = leaderboardItemRef.current;
					timeline.fromTo(
						leaderboardItem.scoreElement,
						{
							xPercent: 100,
							opacity: 0,
						},
						{
							delay: 0.25,
							duration: 0.8,
							opacity: 1.0,
							ease: "power3.inOut",
							onStart: () => {
								if (i <= 3) {
									sfx.play("lbPointRecieved", false, 0.65);
								}
							},
						},
						`showScores+=${t}`
					);
					timeline.to(
						leaderboardItem.backgroundElement,
						{
							delay: 0.4,
							duration: 0.8,
							backgroundColor: LEADERBOARD_RIGHT,
						},
						`showScores+=${t}`
					);
					t = t + (i < 3 ? 0.8 : 0);
				}
			}

			// Slide awarded points to the left in parallel
			timeline.add("awardPlayers");
			for (const player of sortedPlayersByScore) {
				const leaderboardItemRef = getLeaderboardItemsRef(player.connectionId);
				if (leaderboardItemRef && leaderboardItemRef.current) {
					const leaderboardItem = leaderboardItemRef.current;
					if (player.points > player.previousPoints) {
						timeline.fromTo(
							leaderboardItem.scoreElement,
							{
								xPercent: 100,
							},
							{
								duration: 0.8,
								xPercent: 0,
								ease: "power3.inOut",
								onComplete: (points) => {
									sfx.play("lbPointsAdded", false, 0.9);
									if (leaderboardItemRef.current) {
										const leaderboardItem = leaderboardItemRef.current;
										leaderboardItem.setPoints(points);
									}
								},
								onCompleteParams: [player.points],
							},
							"awardPlayers"
						);
					} else {
						leaderboardItem.setPoints(player.points);
					}
				}
			}

			timeline.add(() => void setLeftAlign(false), ">");

			// Conceal awarded points and update total points in parallel
			timeline.add("concealAwardedPlayers");
			for (const player of sortedPlayersByScore) {
				const leaderboardItemRef = getLeaderboardItemsRef(player.connectionId);
				if (leaderboardItemRef && leaderboardItemRef.current) {
					const leaderboardItem = leaderboardItemRef.current;
					timeline.to(
						leaderboardItem.scoreElement,
						{
							duration: 0.8,
							opacity: 0,
							ease: "power3.inOut",
							onComplete: () => {
								if (onSendScore) {
									onSendScore();
								}
							},
						},
						"concealAwardedPlayers"
					);
				}
			}

			// Paint not (visibly) awarded players green/red in parallell with awarding scores
			const nonAwardedPlayers = [...entities].filter(
				(a) => a.isVisibleAfterReorder || (a.isVisibleBeforeReorder && a.points <= a.previousPoints)
			);
			for (const player of nonAwardedPlayers) {
				const leaderboardItemRef = getLeaderboardItemsRef(player.connectionId);
				if (leaderboardItemRef && leaderboardItemRef.current) {
					const leaderboardItem = leaderboardItemRef.current;
					timeline.to(
						leaderboardItem.backgroundElement,
						{
							duration: 0.8,
							backgroundColor:
								player.points > player.previousPoints ? LEADERBOARD_RIGHT : LEADERBOARD_WRONG,
						},
						"concealAwardedPlayers"
					);
				}
			}

			const visiblePlayers = entities.filter((a) => a.isVisibleBeforeReorder || a.isVisibleAfterReorder);

			// Set new points
			timeline.add(() => {
				for (const player of visiblePlayers) {
					const leaderboardItemRef = getLeaderboardItemsRef(player.connectionId);
					if (leaderboardItemRef && leaderboardItemRef.current) {
						const leaderboardItem = leaderboardItemRef.current;
						leaderboardItem.setPoints(player.points);
					}
				}
			}, ">");

			// Rearrange players on board in parallel
			if (leaderboardPlayers.length > 1) {
				timeline.add("rearrangePlayers");
				let movingSoundAdded = false;
				for (const player of visiblePlayers) {
					const leaderboardItemRef = getLeaderboardItemsRef(player.connectionId);
					if (leaderboardItemRef && leaderboardItemRef.current) {
						const leaderboardItem = leaderboardItemRef.current;
						const target = { progress: 0 };
						timeline.to(
							target,
							{
								duration: 1.5,
								progress: 1,
								ease: "power3.inOut",
								onStart: (rankAfterReorder, rankBeforeReorder) => {
									if (leaderboardItemRef.current) {
										const leaderboardItem = leaderboardItemRef.current;

										if (leaderboardItem.backgroundElement) {
											gsap.set(leaderboardItem.backgroundElement, { display: "flex" });
										}
									}
									if (rankAfterReorder !== rankBeforeReorder && !movingSoundAdded) {
										movingSoundAdded = true;
										sfx.play("lbPlayerMoving");
									}
								},
								onStartParams: [player.rankAfterReorder, player.rankBeforeReorder],
								onUpdate: () => {
									if (leaderboardItemRef.current) {
										const leaderboardItem = leaderboardItemRef.current;

										if (leaderboardItem.boxElement) {
											const startY = getItemYPos(
												heightRef.current,
												leaderboardItem.boxElement,
												player.rankBeforeReorder,
												Math.min(leaderboardPlayers.length, LEADERBOARD_NUM_VISIBLE)
											);
											const endY = getItemYPos(
												heightRef.current,
												leaderboardItem.boxElement,
												player.rankAfterReorder,
												Math.min(leaderboardPlayers.length, LEADERBOARD_NUM_VISIBLE)
											);

											gsap.set(leaderboardItem.boxElement, {
												y: lerp(startY, endY, target.progress),
											});
										}
									}
								},
								onComplete: (leaderboardItem, rank) => {
									if (player.rankAfterReorder >= LEADERBOARD_NUM_VISIBLE) {
										if (leaderboardItemRef.current) {
											const leaderboardItem = leaderboardItemRef.current;

											if (leaderboardItem.backgroundElement) {
												gsap.set(leaderboardItem.backgroundElement, { display: "none" });
											}
											if (leaderboardItem.boxElement) {
												gsap.set(leaderboardItem.boxElement, { y: 0 });
											}
										}
									}
									if (leaderboardItemRef.current) {
										const leaderboardItem = leaderboardItemRef.current;

										leaderboardItem.setRank(rank);
									}
								},
								onCompleteParams: [leaderboardItem, player.rankAfterReorder + 1],
							},
							"rearrangePlayers"
						);
					}
				}
			}
		}

		// Move leaderboard items out of screen downwards one by one overlapping
		timeline.add("animateOut", ">1"); // Wait 1s

		const playersAfterReorder = entities.filter((a) => a.isVisibleAfterReorder);
		let animateOutOnStartAdded = false;
		for (const player of playersAfterReorder) {
			const leaderboardItemRef = getLeaderboardItemsRef(player.connectionId);
			if (leaderboardItemRef && leaderboardItemRef.current) {
				const leaderboardItem = leaderboardItemRef.current;

				if (playersAfterReorder.length === 1) {
					const vars = {
						duration: 0.5,
						opacity: 0,
						ease: "linear",
						delay: (playersAfterReorder.length - player.rankAfterReorder) * 0.25,
					};
					if (!animateOutOnStartAdded) {
						// vars.onStart = () => void sfx.play("lbDisappearsFallsDown");
						animateOutOnStartAdded = true;
					}
					timeline.to(leaderboardItem.boxElement, vars, "animateOut");
				} else {
					const target = { progress: 0 };
					timeline.to(
						target,
						{
							progress: 1,
							duration: 0.75,
							ease: "none",
							delay: allLockedPlayersHaveZeroPointsRef.current ? 1 : 0,
							onStart: () => {
								if (leaderboardItemRef.current) {
									const leaderboardItem = leaderboardItemRef.current;
									if (leaderboardItem.backgroundElement) {
										gsap.set(leaderboardItem.backgroundElement, { display: "flex" });
									}
									if (leaderboardItem.boxElement) {
										gsap.set(leaderboardItem.boxElement, {
											opacity: 0,
											y: getItemYPos(
												heightRef.current,
												leaderboardItem.boxElement,
												player.rankAfterReorder,
												Math.min(leaderboardPlayers.length, LEADERBOARD_NUM_VISIBLE)
											),
										});
									}
								}
								if (!animateOutOnStartAdded) {
									animateOutOnStartAdded = true;
									// sfx.play("lbDisappearsFallsDown");
								}
							},
							onUpdate: () => {
								if (leaderboardItemRef.current) {
									const leaderboardItem = leaderboardItemRef.current;
									if (leaderboardItem.boxElement) {
										gsap.set(leaderboardItem.boxElement, {
											opacity: 1 - target.progress,
										});
									}
								}
							},
							onComplete: () => {
								if (leaderboardItemRef.current) {
									const leaderboardItem = leaderboardItemRef.current;
									if (leaderboardItem.backgroundElement) {
										gsap.set(leaderboardItem.backgroundElement, { display: "none" });
									}
									if (leaderboardItem.boxElement) {
										gsap.set(leaderboardItem.boxElement, { opacity: 0 });
									}
								}
							},
						},
						"animateOut"
					);
					/*
					timeline.to(
						target,
						{
							progress: 1,
							duration: 1.2,
							ease: "power2.in",
							delay: (playersAfterReorder.length - player.rankAfterReorder) * 0.25,
							onStart: () => {
								if (leaderboardItemRef.current) {
									const leaderboardItem = leaderboardItemRef.current;

									gsap.set(leaderboardItem.backgroundElement, { display: "flex" });
								}
								if (!animateOutOnStartAdded) {
									animateOutOnStartAdded = true;
									sfx.play("lbDisappearsFallsDown");
								}
							},
							onUpdate: () => {
								if (leaderboardItemRef.current) {
									const leaderboardItem = leaderboardItemRef.current;

									if (leaderboardItem.boxElement) {
										gsap.set(leaderboardItem.boxElement, {
											y:
												heightRef.current * target.progress +
												getItemYPos(
													heightRef.current,
													leaderboardItem.boxElement,
													player.rankAfterReorder,
													Math.min(leaderboardPlayers.length, LEADERBOARD_NUM_VISIBLE)
												),
										});
									}
								}
							},
							onComplete: () => {
								if (leaderboardItemRef.current) {
									const leaderboardItem = leaderboardItemRef.current;
									if (leaderboardItem.backgroundElement) {
										gsap.set(leaderboardItem.backgroundElement, { display: "none" });
									}
									if (leaderboardItem.boxElement) {
										gsap.set(leaderboardItem.boxElement, { y: 0 });
									}
								}
							},
						},
						"animateOut"
					);
*/
				}
			}
		}

		return () => {
			if (timeline) {
				timeline.kill();
				timeline = null;
			}
		};
	}, [getLeaderboardItemsRef, entities, onSendScore]);

	return (
		<ScaleContainer
			ref={ref}
			className={tailwindCascade(
				"md:absolute",
				"md:w-[1920px]",
				"md:h-[1080px]",
				"md:overflow-hidden",
				"md:top-1/2",
				"md:left-1/2",
				"md:transform",
				"md:-translate-x-1/2",
				"md:-translate-y-1/2",
				"relative",
				"flex",
				"flex-col",
				"w-full",
				"h-full",
				"overflow-x-hidden",
				"origin-center",
				"scrollbar-thin",
				"scrollbar-track-transparent",
				"scrollbar-thumb-white-50",
				{
					"overflow-y-hidden": !scrollbarEnabled,
					"overflow-y-auto": scrollbarEnabled,
				}
			)}
		>
			<div
				className={tailwindCascade(
					"absolute",
					"top-0",
					"w-screen",
					"h-full",
					"ease-in-out",
					"duration-500",
					"transition-all",
					{
						"left-0": !leftAlign,
						"-left-8": leftAlign,
					},
					"md:w-full",
					"md:left-0",
					"md:top-0",
					"md:transition-none"
				)}
			>
				<div ref={heightMeasureRef} className="md:absolute fixed w-16 h-full" />
				{entities &&
					entities.map((player, index) => (
						<div className="absolute top-0 flex items-center w-full h-full" key={index}>
							<LeaderboardItem
								ref={(element) => setLeaderboardItemRef(player.connectionId, element)}
								entity={player}
								hidePlayerCountry={hidePlayerCountry}
							/>
						</div>
					))}
			</div>
		</ScaleContainer>
	);
}

export function getLeaderBoardPlayers(players) {
	const connectedPlayers = getConnectedPlayers([...players.values()]).map((player) =>
		pick(player, ["connectionId", "name", "points", "previousPoints", "avatar", "country"])
	);

	return prepareLeaderboardEntities(connectedPlayers);
}

export function getLeaderBoardTeams(teams) {
	const teams2 = cloneDeep(teams);
	return prepareLeaderboardEntities(teams2);
}

function prepareLeaderboardEntities(entities) {
	// Get position of each entity AFTER scores are awarded
	entities.sort((a, b) => b.points - a.points);
	for (let i = 0; i < entities.length; i++) {
		entities[i].rankAfterReorder = i;
		entities[i].isVisibleAfterReorder = i < LEADERBOARD_NUM_VISIBLE;
	}

	// Get position of each entity BEFORE scores are awarded
	entities.sort((a, b) => b.previousPoints - a.previousPoints);
	for (let i = 0; i < entities.length; i++) {
		entities[i].rankBeforeReorder = i;
		entities[i].isVisibleBeforeReorder = i < LEADERBOARD_NUM_VISIBLE;
	}

	// Filter out entitys that are not visible on leaderboard
	const teams3 = entities.filter((entity) => entity.isVisibleBeforeReorder || entity.isVisibleAfterReorder);

	// Finally sort by awarded score
	teams3.sort((a, b) => b.points - b.previousPoints - (a.points - a.previousPoints));

	return teams3;
}

const getItemYPos = (wrapperHeight, boxElement, i, numberOfVisiblePlayers) => {
	const boxHeight = boxElement.offsetHeight;

	if (numberOfVisiblePlayers === 1) {
		return wrapperHeight / 2 - boxHeight / 2;
	}

	const leaderboardHeight = numberOfVisiblePlayers * boxHeight;

	if (i < LEADERBOARD_NUM_VISIBLE) {
		return i * boxHeight - Math.min(leaderboardHeight - wrapperHeight, 0) / 2;
	}

	return wrapperHeight + 12; // add some so that top of avatar circle doesnt show
};

const ScaleContainer = forwardRef(function ScaleContainer({ className, children, props }, forwardedRef) {
	const ref = useRef(null);

	const { offsetWidth: width, offsetHeight: height } = useElementSize(ref.current?.parentElement);
	const scale = useMemo(
		() => Math.floor(Math.min(width / SCALE_WIDTH, height / SCALE_HEIGHT) * 12) / 12,
		[height, width]
	);

	return (
		<div
			ref={ref}
			className={tailwindCascade("md:transform md:scale-container", className)}
			style={{ "--scale": scale }}
			{...props}
		>
			{children}
		</div>
	);
});

function LeaderboardItemCountry({ country }) {
	if (!country || country.length !== 2) {
		country = null;
	}

	if (country) {
		country = country.toLowerCase();
		if (!countries.includes(country)) {
			country = null;
		}
	}

	return (
		<img
			src={`/images/flags/${country || "unknown"}.svg`}
			alt=""
			className="md:ml-0 md:mr-2 md:w-10 md:h-10 w-6 h-6 ml-1 mr-0"
		/>
	);
}

const LeaderboardItem = forwardRef(function LeaderboardItem({ entity, hidePlayerCountry = false }, forwardedRef) {
	const ref = useForwardRef(forwardedRef);

	const [rank, setRank] = useState(0);
	const [points, setPoints] = useState(0);
	const [score, setScore] = useState(0);

	const wrapperRef = useRef();
	const boxRef = useRef();
	const scoreRef = useRef();
	const backgroundRef = useRef();

	useImperativeHandle(ref, () => ({
		get wrapperElement() {
			return wrapperRef.current;
		},
		get boxElement() {
			return boxRef.current;
		},
		get scoreElement() {
			return scoreRef.current;
		},
		get backgroundElement() {
			return backgroundRef.current;
		},
		setRank(rank) {
			setRank(rank);
		},
		setPoints(points) {
			setPoints(points);
		},
		setScore(score) {
			setScore(score);
		},
	}));

	const isHost = entity.connectionId === PLAYING_HOST_CONNECTION_ID;

	return (
		<div
			ref={wrapperRef}
			className={tailwindCascade(
				"md:left-[960px]",
				"absolute",
				"flex",
				"flex-col",
				"justify-center",
				"w-full",
				"h-full"
			)}
		>
			<div
				ref={boxRef}
				className={tailwindCascade(
					"flex",
					"md:w-[960px]",
					"md:h-[90px]",
					"md:-left-[480px]",
					"md:px-0",
					"absolute",
					"left-0",
					"top-0",
					"flex-row",
					"justify-center",
					"w-full",
					"px-4",
					"py-0",
					"text-3xl",
					"font-black",
					"text-white",
					"h-12"
				)}
			>
				<div
					ref={backgroundRef}
					className="md:h-70px md:px-8 h-9 md:space-x-2 -w-full-10 md:w-full relative flex-row items-center justify-start hidden px-2 rounded-full"
					style={{
						background: LEADERBOARD_IDLE,
						boxShadow: isHost ? "0 0 0 2px #000, 0 0 0 7px #ffcd75, 0 0 0 9px #000" : "0 0 0 3px #000",
					}}
				>
					<div className="md:w-12 flex flex-col items-end justify-center flex-grow-0 flex-shrink-0 w-8 h-full px-2">
						<Header className="whitespace-nowrap md:text-3xl text-base font-black text-white">
							{rank || ""}
						</Header>
					</div>
					<div className="md:w-20 relative flex flex-grow-0 flex-shrink-0 w-10 h-full px-2">
						<Avatar
							className="top-1/2 md:w-20 md:h-20 absolute left-0 w-10 h-10 transform -translate-y-1/2"
							playerAvatar={entity.avatar}
							playerName={entity.name}
							playerPoints={entity.previousPoints}
							playerConnectionId={entity.connectionId}
							showShuffle={false}
							showName={false}
							showPoints={false}
							disableBackground={false}
							width={LEADERBOARD_AVATAR_SIZE}
							height={LEADERBOARD_AVATAR_SIZE}
							hostLabel={false}
							hostBorder={true}
						/>
					</div>
					<div className="flex flex-row items-center justify-start flex-1 w-full h-full truncate">
						{!hidePlayerCountry && <LeaderboardItemCountry country={entity.country} />}
						<Header className="md:text-3xl px-2 text-base font-black leading-none truncate">
							{entity.name}
						</Header>
						{isHost && <HostIcon className="md:w-24 w-16" />}
					</div>
					<div className="flex-grow-1 flex flex-col items-end justify-center h-full truncate">
						<Header className="md:text-3xl px-2 text-base font-black truncate">
							{formatPoints(points) || 0}
						</Header>
					</div>

					<div
						className="md:h-70px h-9 w-18 md:w-39 absolute top-0 right-0 z-10"
						style={LEADERBOARD_ITEM_SCORE_STYLE}
					>
						<div className="-left-4 md:-left-10 relative w-full h-full opacity-0" ref={scoreRef}>
							<div className="flex flex-col items-end justify-center w-full h-full">
								<Header className="whitespace-nowrap md:text-3xl text-base font-black text-white">
									{score ? `+${score}` : ""}
								</Header>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
