import React, { useEffect, forwardRef } from "react";

import { useImmer } from "use-immer";

import onWindowResize from "@/helpers/onWindowResize";
import onOrientationChange from "@/helpers/onOrientationChange";

import useForwardRef from "@/hooks/useForwardRef";

import { tailwindCascade } from "@/helpers/tailwindCascade";
import useLayoutStore from "@/stores/layout";

const WIDTH = 1920;
const HEIGHT = 1080;
const FIXED_STEPS = 16;

export default forwardRef(function ScaleWrapper({ className, children, props }, forwardedRef) {
	const ref = useForwardRef(forwardedRef);

	const [size, updateSize] = useImmer({ width: 0, height: 0, marginTop: 0 });

	const setLayoutStore = useLayoutStore((state) => state.set);

	useEffect(() => {
		const resize = () => {
			if (ref.current) {
				let { width, height } = ref.current.getBoundingClientRect();
				const marginTop = width >= 768 ? 44 : 36; // Breakpoints, top menu
				height -= marginTop;

				const r = WIDTH / FIXED_STEPS;
				width = Math.floor(Math.floor(width / r, 1) * r);
				height = Math.floor(Math.floor(height / r, 1) * r);

				updateSize((draft) => {
					draft.width = width;
					draft.height = height;
					draft.marginTop = marginTop / 2;
				});
			}
		};
		resize(); // Initial resize
		const disposeOnWindowResize = onWindowResize(resize);
		const disposeOnOrientationChange = onOrientationChange(resize);
		return () => {
			disposeOnWindowResize();
			disposeOnOrientationChange();
		};
	}, []);

	useEffect(() => {
		let { width, height, marginTop } = size;

		const scale = Math.min(width / WIDTH, height / HEIGHT);

		if (ref.current) {
			ref.current.style.setProperty("--x", `${0}px`);
			ref.current.style.setProperty("--y", `${marginTop}px`);
			ref.current.style.setProperty("--scale", scale);
			ref.current.style.setProperty("--width", `${WIDTH * scale}px`);

			setLayoutStore((draft) => void (draft.containerScale = scale));
		}
	}, [ref, setLayoutStore, size]);

	return (
		<div ref={ref} className={tailwindCascade("relative", className)} {...props}>
			{children}
		</div>
	);
});
