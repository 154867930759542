import { useRef, useCallback, createRef } from "react";

export default function useRefMap() {
	const mapRef = useRef(new Map());

	const getRef = useCallback((key) => mapRef.current.get(key), []);

	const setRef = useCallback((key, value) => {
		const ref = createRef();
		ref.current = value;
		mapRef.current.set(key, ref);
	}, []);

	return [getRef, setRef];
}
